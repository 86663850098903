import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from "docz";
import { useScroom, useDebug } from "@scroom/react";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "usage-with-react"
    }}>{`Usage with React`}</h1>
    <p>{`@scroom/react provides hooks to simplify usage.`}</p>
    <br />
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ npm install @scroom/react
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useScroom, useDebug } from "@scroom/react";
import { useRef, useEffect } from "react";
function App() {
  const ref = useRef();
  const sc = useScroom(ref, {
    offset: 0.5,
    onProgress(e) {
      console.log(e.progress);
    },
  });

  // if you want to debug
  useDebug(sc);

  return <div ref={ref}>...</div>;
}
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={1} __code={'() => {\n  const ref = React.useRef()\n  const sc = useScroom(ref, {\n    onProgress(e) {\n      console.log(e.progress)\n    },\n  })\n  useDebug(sc)\n  return <div ref={ref} style={{ height: \'30vh\' }} />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      useScroom,
      useDebug,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const ref = React.useRef();
        const sc = useScroom(ref, {
          onProgress(e) {
            console.log(e.progress);
          }

        });
        useDebug(sc);
        return <div ref={ref} style={{
          height: '30vh'
        }} />;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      